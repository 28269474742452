import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { pdfjs } from 'react-pdf';
import  { UserContextProvider } from './UserContextProvider';
import App from './App';
import Products from './components/Products';
import ProductDetailed from './components/ProductDetailed';
import hero from '../src/images/image-mirror-jpg.jpg';
import LandingPage from './landing-page/LandingPage';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link as RouterLink,
    Navigate,
    useNavigate, useLocation
} from "react-router-dom";
import About from './components/About';
import Delivery from './components/Delivery';
import {CATEGORIES, GENDERS, SEASONS} from "./utils";

import productDb from "./productDb";
import Style from "./components/Style";
import CategoryHeader from "./components/CategoryHeader";
import BlankHeader from "./components/BlankHeader";
import Cart from "./components/Cart";
import CategoryPage from "./components/CategoryPage";
import SectionHeader from "./components/SectionHeader";
import Section from "./components/Section";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Router>
              <Routes>
                  {/*<Route path="/login" element={<Login />} />*/}
                  <Route path="/about" element={
                      <UserContextProvider>
                          <App>
                              <About/>
                          </App>
                      </UserContextProvider>
                  } />
                  <Route path="/style" element={
                      <UserContextProvider>
                          <App>
                              <Style/>
                          </App>
                      </UserContextProvider>
                  } />
                  <Route path="/category/women" element={
                      <UserContextProvider>
                          <App>
                              <CategoryHeader
                                  title={'Женщины'}
                                  subtitle={`Платья и юбки (${
                                      productDb
                                          .filter(product =>
                                              product.genders.includes(GENDERS.WOMEN) &&
                                              product.categories.includes(CATEGORIES.DRESSES_AND_SKIRTS)
                                          ).length
                                  })`}
                              >
                                  <Products
                                      products={
                                      productDb
                                          .filter(product =>
                                              product.genders.includes(GENDERS.WOMEN) &&
                                              product.categories.includes(CATEGORIES.DRESSES_AND_SKIRTS)
                                          )
                                  }
                                  />
                              </CategoryHeader>
                          </App>
                      </UserContextProvider>
                  }/>
                  <Route path="/category/women/accessories" element={
                      <UserContextProvider>
                              <CategoryPage
                                  title={'Женщины'}
                                  subtitle={`Аксессуары  `}
                                  primaryFilters={[
                                      { type: 'category', value: CATEGORIES.ACCESSORIES, level: 'primary' },
                                      { type: 'gender', value: GENDERS.WOMEN, level: 'primary' }
                                  ]}
                              />
                      </UserContextProvider>
                  }/>
                  <Route path="/category/women/dresses" element={
                      <UserContextProvider>
                          <CategoryPage
                              title={'Женщины'}
                              subtitle={`Платья и юбки  `}
                              primaryFilters={[
                                  { type: 'category', value: CATEGORIES.DRESSES_AND_SKIRTS, level: 'primary' },
                                  { type: 'gender', value: GENDERS.WOMEN, level: 'primary' }
                              ]}
                          />
                      </UserContextProvider>
                  }/>
                  <Route path="/category/men/jackets" element={
                      <UserContextProvider>
                          <App>
                          <CategoryPage
                              title={'Мужчины'}
                              subtitle={`Пиджаки  `}
                              primaryFilters={[
                                  { type: 'category', value: CATEGORIES.JACKETS, level: 'primary' },
                                  { type: 'gender', value: GENDERS.MEN, level: 'primary' }
                              ]}
                          />
                          </App>
                      </UserContextProvider>
                  }/>
                  <Route path="/category/women/:modelId" element={
                      <UserContextProvider>
                          <App>
                              <ProductDetailed/>
                          </App>
                      </UserContextProvider>
                  }/>
                  <Route path="/cart" element={
                      <UserContextProvider>
                          <App>
                              <Cart/>
                          </App>
                      </UserContextProvider>
                  }/>
                  <Route path="/" element={
                      <UserContextProvider>
                          <App>
                          <Grid
                              container
                              sx={{
                                  // gap: { xs: 4, sm: 8 },
                                  // py: { xs: 8, sm: 10 },
                                  //   position: 'sticky',
                                  //   bottom: 0,
                                  // textAlign: {xs: 'right', sm: 'center', md: 'left' },
                                  width: '100%',
                                  marginTop: 'auto'
                              }}
                          >
                              <Box
                                  component='img'
                                  // src={hero}
                                  sx={(theme) => ({
                                      width: '100%',
                                      // backgroundSize: '100% 20%',
                                      backgroundRepeat: 'no-repeat',
                                      pb: 8,
                                      pt: 12
                                  })}
                              />
                          </Grid>
                              {/*<Section*/}
                              {/*    title={'Аксессуары'}*/}
                              {/*    linkUrl={'/category/women/accessories'}*/}
                              {/*    primaryFilters={[*/}
                              {/*        { type: 'category', value: CATEGORIES.ACCESSORIES, level: 'primary' },*/}
                              {/*        { type: 'gender', value: GENDERS.WOMEN, level: 'primary' }*/}
                              {/*    ]}*/}
                              {/*/>*/}
                              <Section
                                  title={'Платья'}
                                  linkUrl={'/category/women/dresses'}
                                  primaryFilters={[
                                  { type: 'category', value: CATEGORIES.DRESSES_AND_SKIRTS, level: 'primary' },
                                  { type: 'gender', value: GENDERS.WOMEN, level: 'primary' }
                              ]}
                              />
                              {/*<Section*/}
                              {/*    title={'Аксессуары'}*/}
                              {/*    linkUrl={'/category/women/accessories'}*/}
                              {/*    primaryFilters={[*/}
                              {/*        { type: 'category', value: CATEGORIES.ACCESSORIES, level: 'primary' },*/}
                              {/*        { type: 'gender', value: GENDERS.WOMEN, level: 'primary' }*/}
                              {/*    ]}*/}
                              {/*/>*/}
                              {/*<Section*/}
                              {/*    title={'Пиджаки'}*/}
                              {/*    linkUrl={'/category/men/jackets'}*/}
                              {/*    primaryFilters={[*/}
                              {/*        { type: 'category', value: CATEGORIES.JACKETS, level: 'primary' },*/}
                              {/*        { type: 'gender', value: GENDERS.MEN, level: 'primary' }*/}
                              {/*    ]}*/}
                              {/*/>*/}
                          </App>
                      </UserContextProvider>
                  }/>
                  {/*<Route path="*" element={<NotFound/>} />*/}
              </Routes>
      </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
