import * as React from 'react';
import {alpha, Select} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CheckIcon from '@mui/icons-material/Check';
import {ClickAwayListener} from "@mui/material";
import Chip from '@mui/material/Chip';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import img from '../img.png';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Grid from '@mui/material/Grid';
import {useContext, useEffect, useState} from 'react';
import { UserContext } from '../UserContext';
import { colorNameToCode } from '../productDb';

import {addCartItem, CATEGORIES} from '../utils';
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";


const CategoryHeader = (props) => {
    const { children, title, subtitle, products, pickedFilters, setPickedFilters} = props;

    // useEffect(() => {
    //     setPickedFilters([...initialFilters])
    // }, []);

    const DEFAULT_FILTERS = {
        colors: {
            isEnabled: false,
            isOpened: false,
            data: []
        },
        sizes: {
            isEnabled: false,
            isOpened: false,
            data: []
        }
    }
    const [filters, setFilters] = useState(DEFAULT_FILTERS);

    // const [pickedFilters, setPickedFilters] = useState([]);

    const enabledFiltersSortOrder = [
        'color', 'category', 'size', 'price'
    ]

    const handleClearFilters = () => {
        const primaryFilters = pickedFilters.filter((fltr) => fltr.level == 'primary');
        setPickedFilters(primaryFilters);
    }

  return (
      <Box
          sx={(theme) => ({
              width: '100%',
              backgroundSize: '100% 20%',
              backgroundRepeat: 'no-repeat',
          })}
      >
          {/*<Container*/}
          {/*    container*/}
          {/*>*/}
          {/*</Container>*/}
          <Box
              sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexDirection: 'column',
                  pt: { xs: 14, sm: 20 },
                  pb: { xs: 8, sm: 12 },
              }}
          >
              <Box
                  sx={{
                      width: '100%'
                  }}
              >
                  <Container>
                      <Container>
                          <Typography variant='body1' align='left' width='100%'>
                              {title}
                          </Typography>
                      </Container>
                      <Container
                          sx={{
                              pb: 2
                          }}
                      >
                          <Typography variant='categorySubtitle1' align='left' width='100%'>
                              {`${subtitle}`}
                              <Typography variant={'h6'} sx={{display: "inline-block"}}>{`(${
                                  products.filter(product => {
                                      if (!pickedFilters.length) {
                                          return true;
                                      }
                                      
                                      for (let i = 0; i < pickedFilters.length; i++) {
                                        if (pickedFilters[i].type == 'category' && !product.categories.includes(pickedFilters[i].value)) {
                                            return false
                                        }
                                      }
                                        return true;
                                      }
                                  )
                                      .map(it => it.modelId)
                                      .filter(function onlyUnique(value, index, array) {
                                          return array.indexOf(value) === index;
                                      })
                                      .length
                                  // productDb
                                  //     .filter(product =>
                                  //         product.genders.includes(GENDERS.WOMEN) &&
                                  //         product.categories.includes(CATEGORIES.DRESSES_AND_SKIRTS)
                                  //     )
                              })`}</Typography>
                          </Typography>
                      </Container>
                  </Container>
              </Box>
              <Box
                  sx={{
                      backgroundColor: "#fffcf7",
                      height: '50px',
                      width: '100%'
                  }}
              >
                  <Container
                      sx={{
                          display: 'flex',
                          // flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          height: '100%'
                          // pt: { xs: 14, sm: 20 },
                          // pb: { xs: 8, sm: 12 },
                      }}
                  >
                      <Box
                          sx={{
                              display: 'flex',
                              // flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              height: '100%'
                              // pt: { xs: 14, sm: 20 },
                              // pb: { xs: 8, sm: 12 },
                          }}
                      >
                          <FilterLink
                              text='Цвет'
                              number={pickedFilters.filter((fltr) => fltr.type == 'color').length}
                              isOpened={filters.colors.isOpened}
                              onClick={(isOpen) => {
                                  setFilters((prevState) => ({...prevState, colors: { ...prevState.colors, isOpened: !prevState.colors.isOpened}}))
                              }}
                          />
                          <FilterLink
                              text='Размер'
                              number={ pickedFilters.filter((fltr) =>  fltr.type == 'size').length}
                              isOpened={filters.sizes.isOpened}
                              onClick={(isOpen) => {
                                  setFilters((prevState) => ({...DEFAULT_FILTERS, sizes: { ...prevState.sizes, isOpened: !prevState.sizes.isOpened}}))
                              }}
                          />
                      </Box>
                      <Box
                          sx={{
                              marginLeft: "auto",
                              display: 'flex',
                              // flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              height: '100%'
                          }}
                      >
                          <Container
                              sx={{
                                  marginLeft: 'auto',
                                  '&:hover': {
                                      cursor: 'pointer'
                                  }
                              }}
                              onClick={ handleClearFilters }
                          >
                              <Typography
                                  // sx={{
                                  //     borderRight: "1px solid gray",
                                  //     paddingRight: '10px'
                                  // }}
                              >
                                   Очистить
                              </Typography>
                          </Container>
                          {/*<Container>*/}
                          {/*    <Typography*/}
                          {/*        sx={{*/}
                          {/*            '&:hover': {*/}
                          {/*                cursor: 'pointer'*/}
                          {/*            },*/}
                          {/*            whiteSpace: 'nowrap'*/}
                          {/*        }}*/}
                          {/*    >*/}
                          {/*        Сортировать по*/}
                          {/*    </Typography>*/}
                          {/*    /!*<Select*!/*/}
                          {/*    /!*    labelId="demo-simple-select-label"*!/*/}
                          {/*    /!*    id="demo-simple-select"*!/*/}
                          {/*    /!*    // value={age}*!/*/}
                          {/*    /!*    placeholder={"Сортировать по"}*!/*/}
                          {/*    /!*    label="Сортировать по"*!/*/}
                          {/*    /!*    // onChange={handleChange}*!/*/}
                          {/*    /!*>*!/*/}
                          {/*    /!*    <MenuItem value={10}>Ten</MenuItem>*!/*/}
                          {/*    /!*    <MenuItem value={20}>Twenty</MenuItem>*!/*/}
                          {/*    /!*    <MenuItem value={30}>Thirty</MenuItem>*!/*/}
                          {/*    /!*</Select>*!/*/}
                          {/*</Container>*/}
                      </Box>
                  </Container>
                  <ColorFilter
                    enableFilter={() => props.enableFilter }
                    close={ () => setFilters(DEFAULT_FILTERS) }
                    onChange={(color) => {
                        setPickedFilters(
                        (prevState) => {
                            const nextState = [...prevState];
                            const idx = nextState.findIndex((fltr => fltr.type == 'color' && fltr.value == color));

                            if (idx != -1) {
                                nextState.splice(idx, 1);
                                return nextState;
                            } else {
                                return  [...nextState, { type: "color", value: color }];
                            }
                        }
                    )}}
                    opened={ filters.colors.isOpened }
                    data={
                        products.map((p) => p.color)
                            .filter(function onlyUnique(value, index, array) {
                                return array.indexOf(value) === index;
                            })
                    }
                    pickedFilters={ pickedFilters }
                  />
                  <TextFilter
                      close={ () => setFilters(DEFAULT_FILTERS) }
                      onChange={
                            (size) => { setPickedFilters((prevState) => {
                                const nextState = [...prevState];

                                const idx = nextState.findIndex((fltr => fltr.type == 'size' && fltr.value == size));

                                if (idx != -1) {
                                    nextState.splice(idx, 1);
                                    return nextState;
                                } else {
                                    return  [...nextState, { type: "size", value: size }];
                                }

                                    // return [...prevState, {
                                    //     type: "category", value: text }];
                            }
                            )}
                      }
                      opened={ filters.sizes.isOpened }
                      data={
                          products.map((p) => p.size)
                              .filter(function onlyUnique(value, index, array) {
                                  return array.indexOf(value) === index;
                              })
                      }
                      pickedFilters={ pickedFilters }
                  />
                  <PickedFilter
                      data={ pickedFilters.filter((fltr) => fltr.level != 'primary').sort((pf1, pf2) => {
                          return enabledFiltersSortOrder.indexOf(pf1.type) - enabledFiltersSortOrder.indexOf(pf2.type);
                      })}
                      removeFilter={
                        (filter) => { setPickedFilters((prevState) => {
                          const nextState = [...prevState];

                          const idx = nextState.findIndex((fltr => fltr.type == filter.type && fltr.value == filter.value));
                            nextState.splice(idx, 1);

                            return  [...nextState]
                              }
                          )}
                      }
                  />
              <Container
                  sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                      // pt: { xs: 14, sm: 20 },
                      // pb: { xs: 8, sm: 12 },
                  }}
              >
                  { children }
              </Container>
              </Box>
          </Box>
      </Box>
  );
}

const Filter = (props) => {

    const { onItemClick, data, opened, close } = props;
    return (
        opened ?
            <ClickAwayListener onClickAway={ close }>
            <Box
                sx={{
                    backgroundColor: "#fffcf7",
                    borderTop: '1px solid #f6f1eb',
                    position: "absolute",
                    height: '100px',
                    width: '100%',
                    zIndex: '999'
                }}
            >
                <Container
                    sx={{
                        display: 'flex',
                        // flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        height: '100%'
                        // pt: { xs: 14, sm: 20 },
                        // pb: { xs: 8, sm: 12 },
                    }}
                >
                    <Container
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        <Grid container>
                            {
                                data
                                    .map(item => {
                                        return (
                                            <Grid item >
                                                <Box
                                                    sx={{
                                                        display: "inline-flex",
                                                        alignItems: "center",
                                                        flexDirection: "flex-start",
                                                        marginRight: "30px",
                                                        '&:hover': {
                                                            cursor: 'pointer'
                                                        },
                                                    }}
                                                    onClick={ onItemClick }
                                                >
                                                    { item }
                                                </Box>
                                            </Grid>);
                                    })
                            }
                        </Grid>
                    </Container>
                    {/*<Container*/}
                    {/*    sx={{*/}
                    {/*        display: 'flex',*/}
                    {/*        justifyContent: 'flex-start',*/}
                    {/*        alignItems: 'center'*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {*/}
                    {/*        filters.colors.data.map(color => color)*/}
                    {/*    }*/}
                    {/*</Container>*/}
                </Container>
            </Box>
            </ClickAwayListener>
                : null
    )
}

const TextFilter = (props) => {
   const { data, opened, onChange: handleChange, pickedFilters, close } = props;
   return (
       <Filter
           opened={opened}
           close={close}
           onItemClick={() => {}}
           data={
               data
                   .map(textItem => (
                       <Box
                           sx={{
                               display: "inline-flex",
                               alignItems: "center",
                               flexDirection: "flex-start",
                               marginRight: "30px",
                               '&:hover': {
                                   cursor: 'pointer'
                               },
                           }}
                           onClick={() => { handleChange(textItem)}}
                       >
                           <Box
                               sx={{
                                   width: "16px",
                                   display: "inline-block",
                                   height: "16px",
                                   backgroundColor: "#fff",
                                   border: "1px solid gray",
                                   marginRight: "10px"
                               }}
                           >
                               {
                                    pickedFilters.findIndex((fltr) => fltr.value == textItem) != -1 ?
                                   <CheckIcon
                                       sx={{
                                           position: 'relative',
                                           width: "16px",
                                           height: "16px"
                                       }}
                                       fontSize='small'/> : null
                               }
                           </Box>
                           <Typography
                               sx={{
                                   display: "inline-block"
                               }}
                           >
                               {textItem}
                           </Typography>
                       </Box>))
           }
           type='default'
       />
   );
}

const ColorFilter = (props) => {
    const { data, opened, onChange: handleChange, pickedFilters, close } = props;

    return (
                <Filter
                    close={close}
                    opened={opened}
                    onItemClick={() => {}}
                    data={
                        data
                            .map(color => (
                                <Box
                                    sx={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                        flexDirection: "flex-start",
                                        marginRight: "30px",
                                        '&:hover': {
                                            cursor: 'pointer'
                                        },
                                    }}
                                    onClick={() => handleChange(color)}
                                >
                                    <Box
                                        sx={{
                                            width: "16px",
                                            display: "inline-block",
                                            height: "16px",
                                            backgroundColor: colorNameToCode[color],
                                            border:  "1px solid gray",
                                            marginRight: "10px"
                                        }}
                                    >
                                        {
                                            pickedFilters.findIndex((fltr) => fltr.value == color) != -1 ?
                                                <Box
                                                    sx={{
                                                        position: "relative",
                                                        top: '22px',
                                                        width: "16px",
                                                        height: "2px",
                                                        backgroundColor: "#000"
                                                    }}
                                                /> : null
                                        }
                                    </Box>
                                    <Typography
                                        sx={{
                                            display: "inline-block"
                                        }}
                                    >
                                        {color}
                                    </Typography>
                                </Box>))
                    }
                    type='default'
                />
    );
}

const FilterLink = (props) => {
    const { text, number, onClick, isOpened } = props;
   return (
       <Container
           sx={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'flex-start',
               userSelect: 'none',
               '&:hover': {
                   cursor: 'pointer'
               }
           }}
            onClick={ () => {
                onClick();
            }}
       >
           <Typography sx={{wordBreak: 'keep-all'}}>{`${text} ${number ? "(" + number + ")" : ""}`}</Typography>
           { isOpened ? <ExpandLessIcon fontSize='small'/> : <ExpandMoreIcon fontSize='small'/> }
       </Container>
   );
}

const PickedFilter = (props) => {
    const { data, removeFilter } = props;
   return (
       <Box
           sx={{
               backgroundColor: "#f6f1eb",
               borderTop: '1px solid #f6f1eb',
               // height: '50px',
               width: '100%',
               zIndex: '999'
           }}
       >
           <Container
               sx={{
                   display: 'flex',
                   // flexDirection: 'row',
                   alignItems: 'center',
                   justifyContent: 'flex-start',
                   height: '100%',
                   // pt: { xs: 14, sm: 20 },
                   // pb: { xs: 8, sm: 12 },
               }}
           >
               <Container
                   sx={{
                       display: 'flex',
                       justifyContent: 'flex-start',
                       alignItems: 'center',
                   }}
               >
                   <Grid container>
                       {
                           data
                               .map(item => {
                                   return (
                                       <Grid item >
                                           <Box
                                               sx={{
                                                   display: "inline-flex",
                                                   alignItems: "center",
                                                   flexDirection: "flex-start",
                                                   marginRight: "10px",
                                                   marginTop: "5px",
                                                   '&:hover': {
                                                       cursor: 'pointer'
                                                   },
                                               }}
                                               onClick={ () => {} }
                                           >

                                              <Chip deleteIcon={<CloseIcon
                                                  fontSize='30'
                                                  sx={{color: '#000'}}/>}  label={item.value} variant="outlined" onDelete={
                                                  (e) => { removeFilter(item)}
                                              }/>
                                           </Box>
                                       </Grid>);
                               })
                       }
                   </Grid>
               </Container>
               {/*<Container*/}
               {/*    sx={{*/}
               {/*        display: 'flex',*/}
               {/*        justifyContent: 'flex-start',*/}
               {/*        alignItems: 'center'*/}
               {/*    }}*/}
               {/*>*/}
               {/*    {*/}
               {/*        filters.colors.data.map(color => color)*/}
               {/*    }*/}
               {/*</Container>*/}
           </Container>
       </Box> : null
   );
}

export default CategoryHeader;
