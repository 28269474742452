import productDb from '../productDb';
import Products from "./Products";
import App from '../App';
import CategoryHeader from "./CategoryHeader";

import {CATEGORIES} from "../utils";
import {GENDERS} from "../utils";

import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";


export default function CategoryPage(props) {
    const { title, subtitle, primaryFilters } = props;
    const { key } = useLocation();
    // const navigate = useNavigate();
    // const {cart, updateState} = useContext(UserContext);
    // const [ paymentDetails, setPaymentDetailes ] = useState({
    //     isPaymentDetailsStep: false
    // })

    useEffect(() => {
        setPickedFilters(primaryFilters);
    }, [key]);

    const [pickedFilters, setPickedFilters] = useState(primaryFilters);

    const productsUnderPrimaryFilters = productDb
        .filter(product => {
                let genderFlag = false;
                let categoryFlag = false;
                for (let i = 0; i < pickedFilters.length; i++) {
                    if (pickedFilters[i].level == 'primary') {
                        if (pickedFilters[i].type == 'category' && product.categories.includes(pickedFilters[i].value)) {
                            categoryFlag = true;
                        }

                        if (pickedFilters[i].type == 'gender' && product.genders.includes(pickedFilters[i].value)) {
                            genderFlag = true;
                        }
                    }
                }

                return genderFlag && categoryFlag;
            }
        )
    const products = productDb
        .filter(product => {
                // if (!pickedFilters.filter((fltr) => fltr.level != "primary").length) {
                //     return true;
                // }
                let genderFlag = false;
                let categoryFlag = false;
                let sizeFlag = false;
                let colorFlag = false;
                for (let i = 0; i < pickedFilters.length; i++) {
                    if (pickedFilters[i].level == 'primary') {
                        if (pickedFilters[i].type == 'category' && product.categories.includes(pickedFilters[i].value)) {
                            categoryFlag = true;
                        }

                        if (pickedFilters[i].type == 'gender' && product.genders.includes(pickedFilters[i].value)) {
                            genderFlag = true;
                        }
                    }

                    if (pickedFilters[i].type == 'size' && product.size == pickedFilters[i].value) {
                        sizeFlag = true;
                    }

                    if (pickedFilters[i].type == 'color' && product.color == pickedFilters[i].value) {
                        colorFlag = true;
                    }
                }

                return (sizeFlag || !pickedFilters.filter(fltr => fltr.type == 'size').length) &&
                    (colorFlag || !pickedFilters.filter(fltr => fltr.type == 'color').length) && genderFlag && categoryFlag;
            }
        )

    return (
        <App>
            <CategoryHeader
                title={title}
                subtitle={subtitle}
                pickedFilters={ pickedFilters }
                setPickedFilters={ setPickedFilters }
                products={ productsUnderPrimaryFilters }
            >
                <Products
                    products={ products }
                />
            </CategoryHeader>
        </App>
    );
}
