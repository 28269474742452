import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import vm_pink_2_small from '../images/pink-dress/vm_pink_2_small.jpg';
import vm_pink_4_small from '../images/pink-dress/vm_pink_4_small.jpg';
import bal_1 from '../images/balaclava/balaclava_1.jpg';
import bal_2 from '../images/balaclava/balaclava_2.jpg';
import {getTraitValue} from "../utils";

export default function Product(props) {
    const navigate = useNavigate();
    const {
        id,
        modelId,
        imagesList,
        productSrc,
        traits,
        title,
        price
    } = props;

    // const title = getTraitValue('title', traits);
    // const price = getTraitValue('price', traits);

    const [ card, setCard ] = useState({
        imageNum: 0,
        arrowsVisible: false
    });
    // const {title = '', items = [], id} = props;
    // const {cart, updateState} = useContext(UserContext);
    const handleCardClick = () => {
        navigate('/category/women/' + modelId);
    }

    const handleCardMouseEnter = () => {
        setCard(prevState => ({
            ...prevState,
            imageNum: 1,
            arrowsVisible: true
        }))
    }

    const handleCardMouseLeave = () => {
        setCard(prevState => ({
            ...prevState,
            imageNum: 0,
            arrowsVisible: false
        }))
    }

    const handleImageClickLeft = () => {
        setCard(prevState => {
            let newImageNum;
            if (prevState.imageNum == 0) {
              newImageNum = images.length - 1;
            } else {
             newImageNum =  prevState.imageNum - 1;
            }

            return {
                ...prevState,
                imageNum: newImageNum
            }
        })
    }
    const handleImageClickRight = () => {
        setCard(prevState => {
            let newImangeNum;
            if (prevState.imageNum == images.length - 1) {
                newImangeNum = 0;
            } else {
                newImangeNum = prevState.imageNum + 1;
            }

            return {
                ...prevState,
                imageNum: newImangeNum
            }
        })
    }

    const images = imagesList;

    return (
        <Grid item xs={6} lg={4}>
        <Box
            sx={{
                // position: 'relative',
                // width: 400
                '&:hover': {
                    cursor: 'pointer'
                },
            }}
            onMouseEnter={ handleCardMouseEnter }
            onMouseLeave={ handleCardMouseLeave }
        >
            <Box
                component="img"
                sx={{
                    height: '100%',
                    width: '100%'
                    // maxHeight: { xs: 640, md: 630 },
                    // maxWidth: { xs: 350, md: 630 },
                }}
                alt=""
                src={ images[card.imageNum] }
                onClick={ handleCardClick }
            >
            </Box>
            {
                // card.arrowsVisible ?
                //     <ChevronLeftOutlinedIcon
                //         sx={{
                //             position: "absolute",
                //             top: '50%',
                //             transform: 'translateY(-50%)',
                //             left: '7px',
                //             zIndex: '9999',
                //             '&:hover': {
                //                 backgroundColor: '#fffe'
                //             },
                //             fontWeight: 100,
                //             backgroundColor: '#fff9',
                //             borderRadius: '50%'
                //         }}
                //         onClick={handleImageClickLeft}/> : null
            }
            {
                // card.arrowsVisible ?
                //     <ChevronRightOutlinedIcon
                //         sx={{
                //             position: "absolute",
                //             top: '50%',
                //             transform: 'translateY(-50%)',
                //             right: '7px',
                //             zIndex: '9999',
                //             '&:hover': {
                //                 backgroundColor: '#fffe'
                //             },
                //             backgroundColor: '#fff9',
                //             borderRadius: '50%'
                //         }}
                //         onClick={ handleImageClickRight }/> : null
            }
        </Box>
            <Typography align='left'>{ title }</Typography>
            <Typography align='left'>{ price }</Typography>
        </Grid>
    );
}
