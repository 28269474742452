import React from 'react'
import productDb from "./productDb";


/**
 * Default application state
 */
export const defaultState = {
    user: {},
    cart: {
        products: [],
        //todo remove
        open: false,
        phone: '+7',
        name: '',
        email: '',
        items: [],
        delivery: {
            isPicked: false,
            address: ''
        }
    },
    payment: {},
    updateState: (newState) => {},
}

/**
 * Creating the Application state context for the provider
 */
export const UserContext = React.createContext(defaultState)
